<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
      <router-link to="/report" class="text-white">
        <i class="ni ni-badge"></i> Report
      </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i>General Trade Report</span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-inline">
                <div class="text-center">
                  <div class="input-group">
                    <span class="input-group-text">KPI Index Details</span>
                    <select v-model="KpiReferenceIdval" class="form-control btn btn-success ml-2">
                      <option value="0"></option>
                      <option v-for="item in KpiReferenceItems" :key="item.Id" v-bind:value="item.Id">
                        {{ item.KpiDescription }}
                      </option>
                    </select>
                    <input v-if="KpiReferenceIdval" v-model="Titleval" placeholder="Enter Title" class="form-control ml-2"
                      required />
                    <button v-if="Titleval" class="btn btn-secondary ml-2" @click.prevent="getreport()">
                      <i class="fa fa-print"></i> Spool Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
           <Report/>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>
  
<script>
import moment from "moment";
import Alert from "@/mixins/Alert.mixins"
import Report from "@/components/Report";
export default {
  name: "GTReport",
  mixins: [Alert],
  components: {
        Report
  },
  data() {
    return {
      KpiReferenceIdval: "",
      Titleval: "",
      KpiReferenceItems: null,   
      currdate: null,
      onloader: "none",
    };
  },

  methods: {
    getcurendate() {
      var today = new Date();
      this.currdate =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
    },
    getkpireference() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireference")
        .then((resp) => {
          this.KpiReferenceItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getreport() {
      this.onloader = "flex";
      let KpiReferenceId = this.KpiReferenceIdval
      let ReportTitle =`General Trade Report : ${this.Titleval}`;
      var payload = {
        KpiReferenceId,
        ReportTitle
      }
      this.$store
        .dispatch("GtGeneralReport", payload)
        .then((resp) => {        
          this.onloader = "none";         
          if(resp.responseCode=='00'){
            this.ShowAlertWithRefresh(resp.responseMessage, '');
          }else{
            this.ShowAlertError(resp.responseMessage, '');
          }             
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.ShowAlertError(err, '');
        });
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    }   
  },
  mounted() {
    this.getkpireference();
    this.getcurendate();
  },
};
</script>
  
<style>
.borderall {
  text-align: center;
  border: 3px solid #000;
}

.bordercol {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border: thin solid #000;
}

.padbody {
  padding: 20px;
}

.padhead {
  padding: 5px;
  border: 3px solid #606060;
}

.pad {
  padding: 10px;
}

table {
  font-size: 9pt;
  width: 100%;
}

.bodytext {
  font-size: 9pt;
}
</style>
  